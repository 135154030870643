<template>
 <v-theme-provider dark>
  <base-section
    id="cip"
    class="accent"
    space="36"
  >
    <v-spacer />

    <div>
      <v-tabs
        class="hidden-sm-and-down"
        optional
        background-color="transparent"
      >
        <v-tab
          v-for="(name, i) in items"
          :key="i"
          :to="{ name }"
          :exact="name === 'Home'"
          :ripple="false"
          class="font-weight-bold"
          min-width="96"
          text
        >
          {{ name }}
        </v-tab>
      </v-tabs>
    </div>

    <v-container>
      <base-section-heading title="CIP(Cleaning In Place)란?" />

      <v-container
      class="text-center"
      >
          식품제조설비를 분해하거나 개방함이 없이 또 작업자가 직접(수동) 작업을 하지 않고, <br>
          자동화된 장치로 세척액의 순환을 통해 생산설비 및 파이프 라인을 완벽시 세척하는 방식을 말합니다. <br>
          즉, 세척액의 순환을 통해 피 세척물을 세정하게 됩니다.<br>
          <br>
          세척용액이 설비 내부에서 소용돌이를 일으키며 빠르게 순환하며, 분사(jetting) 혹은 분무(Spraying)가 이루어 집니다.<br>
          CIP 작업을 통해 설비 내부는 오염제거 및 미생물 증식이 억지된 위생상태가 됩니다.
      </v-container>
      <v-container>
      </v-container>

      <v-container>
      </v-container>
      <base-section-heading title="제품군" />
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                CIP용 알카리 세척제
              </v-subheader>

              <v-list
              class="accent"
              >
                <template v-for="(item, index) in proditems1">
                  <v-list-item
                    v-if="item.action"
                    :key="item.title"
                  >
                    <v-list-item-action>
                      <v-icon>{{ item.action }}</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider
                    v-else-if="item.divider"
                    :key="index"
                    :inset="inset"
                  ></v-divider>
                </template>
              </v-list>
            </v-card>
          </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                CIP용 첨가제
              </v-subheader>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                CIP용 산성 세척제
              </v-subheader>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                식품첨가물/CIP용 세척제
              </v-subheader>

              <v-list
              class="accent"
              >
                <template v-for="(item, index) in proditems2">
                  <v-list-item
                    v-if="item.action"
                    :key="item.title"
                  >
                    <v-list-item-action>
                      <v-icon>{{ item.action }}</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider
                    v-else-if="item.divider"
                    :key="index"
                    :inset="inset"
                  ></v-divider>
                </template>
              </v-list>
            </v-card>
          </v-col>
    </v-container>
  </base-section>
 </v-theme-provider>
</template>

<script>
  export default {
    name: 'Sectioncip',
    data: () => ({
      drawer: null,
      items: [
        'Cip',
        'Cop',
        '세병첨가제',
        '컨베이어 윤활제',
        '생수공장용',
        '수질용',
      ],
      inset: false,
      proditems1: [
        {
          action: 'mdi-label',
          title: '식음료, 주류, 유가공용 알카리 세척제',
        },
        {
          divider: true,
        },
        {
          action: 'mdi-label',
          title: '목장 착유기용 알카리 세척제',
        },
        {
          divider: true,
        },
        {
          action: 'mdi-label',
          title: 'Non-caustic type 알카리 세척제',
        },
      ],
      proditems2: [
        {
          action: 'mdi-label',
          title: '식품첨가물/CIP용 알카리 세척제',
        },
        {
          divider: true,
        },
        {
          action: 'mdi-label',
          title: '식품첨가물/CIP용 산성 세척제',
        },
      ],
    }),
    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },

  }
</script>
<style scoped>
.v-subheader {
    font-size: 18px;
    color: coral !important;
}

</style>
